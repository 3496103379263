<template>
  <span class="upload-input">
    <a :title="value | name" class="upload-display text-overflow">
      <i class="el-icon-document"></i>
      {{ value | name }}
    </a>
    <label style="width: 40px" class="file-upload">
      <input type="file" id="file" :accept="accept || 'image/jpeg,image/png'" ref="file" />
      <a v-show="!value" style="color: #409eff; padding-left: 10px">上传</a>
    </label>
    <a
      v-show="value"
      style="color: #f56c6c; padding-left: 10px; width: 40px; display: table"
      @click="del"
    >删除</a>
    <a
      v-show="value"
      :href="value"
      style="color: #409eff; padding-left: 10px; width: 40px; display: table"
      target="_back"
    >预览</a>
  </span>
</template>

<script>
import axios from 'axios';
// import config from "/src/config/config";
export default {
  name: 'Upload',
  props: {
    value: String, // key code,
    path: String,
    accept: String
  },
  data () {
    return {
      process: 0
    };
  },
  filters: {
    name (val) {
      const paths = (val || '').split('/');
      return paths[paths.length - 1] || '请上传';
    }
  },
  methods: {
    del () {
      this.$emit('input', '');
    }
  },
  mounted () {
    setTimeout(() => {
      console.log(this.path);
      this.$refs.file.addEventListener('change', async event => {
        const originfile = event.target.files[0];
        if (originfile.size > 2097152) {
          this.$message.error('错了哦，超过文件最大限制-2M');
        }
        event.target.value = null;
        const from = new FormData();
        from.append('file', originfile);
        axios({
          url: `${window.location.origin}${this.path}`,
          method: 'post',
          data: from,
          headers: {
            Authorization: window.localStorage.getItem('token')
          }
        })
          .then(res => {
            const path = res.data.data;
            this.$emit('input', path);
            this.$parent.clearValidate();
          })
          .catch(err => {
            console.log(err);
          });
      });
    }, 100);
  }
};
</script>
<style lang="scss">
.is-error .upload-input {
  border-color: #f56c6c !important;
  color: #f56c6c;
}
</style>
<style lang="scss" scoped>
.file-upload > input {
  display: none;
}
.upload-input {
  border: 1px solid #dcdfe6;
  padding: 0 19px;
  display: flex;
  padding-right: 5px;
}
.upload-display {
  flex-grow: 1;
}
</style>
